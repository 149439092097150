@use '@angular/material' as mat;
@import "./assets/lib/suite/suite.min.css";
@import 'ngx-toastr/toastr';

@include mat.core();

$md-brandpalette: (
        50 : #e0effc,
        100 : #b3d7f7,
        200 : #80bdf2,
        300 : #4da3ed,
        400 : #268fe9,
        500 : #007be5,
        600 : #0073e2,
        700 : #0068de,
        800 : #005eda,
        900 : #004bd3,
        A100 : #fbfcff,
        A200 : #c8d8ff,
        A400 : #95b3ff,
        A700 : #7ba1ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

$schedules-web-primary: mat.define-palette($md-brandpalette);
$schedules-web-accent: mat.define-palette($md-brandpalette, A200, A100, A400);

$schedules-web-theme: mat.define-light-theme((
        color: (
                primary: $schedules-web-primary,
                accent: $schedules-web-accent,
        ),
));

html {

  @include mat.core-theme($schedules-web-theme);
  @include mat.all-component-themes($schedules-web-theme);

  body {
    height: 100%;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    .mat-mdc-form-field-infix {
      width: auto;
    }

    .header {
      font-size: 20px;
      font-weight: 500;
    }

    .title {
      font-size: 16px;
      color: rgba(0, 0, 0, .7);
    }
  }

  .title-panel {
    mat-expansion-panel-header {
      color: #2a2b2d !important;

    }
  }
}



